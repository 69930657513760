import React, { createContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useSocket } from "contexts/Socket";

const Context = createContext({
  socket: null,
  resultList: [],
});

const Provider = ({ children }) => {
  const { gameId } = useParams();
  const socket = useSocket("https://egoteen.withplus.live");

  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("getResultList", { gameId }, (data) => {
      setResultList(data);
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connected");

      socket.emit("joinRoom", { gameId });
    });

    socket.on("updateResultList", (data) => {
      setResultList(data);
    });

    return () => {
      socket.off("connect");
      socket.off("updateResultList");
    };
  });

  const value = {
    socket,
    resultList,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
